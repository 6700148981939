
import { defineComponent } from "vue";
import CreateCardEntryForm from "@/views/cards/constituent/CreateCardEntryForm.vue";

export default defineComponent({
  name: "CreateGiftCardEntry",
  components: {
    CreateCardEntryForm,
  },
  setup() {
    return {};
  },
});
