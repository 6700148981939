<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class="" @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="description" class="form-label">
                  Type/Description
                </label>
                <InputWrapper :errors="formError.description">
                  <input
                    type="text"
                    v-model="formData.description"
                    class="form-control"
                    id="description"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="description" class="form-label">
                  Range/Denomination
                </label>
                <InputWrapper :errors="formError.range">
                  <input
                    type="text"
                    pattern="^(?:\d+)(?:(?:-\d+)|(?:,\d+)+)?$"
                    v-model="formData.range"
                    class="form-control"
                    id="range"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="rate" class="form-label"> Rate </label>
                <InputWrapper :errors="formError.rate">
                  <input
                    type="number"
                    v-model="formData.rate"
                    class="form-control"
                    id="rate"
                    step="0.01"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="tac" class="form-label">
                  Terms And Condition
                </label>
                <InputWrapper :errors="formError.rate">
                  <textarea
                    class="form-control"
                    id="tac"
                    rows="6"
                    v-model="formData.tac"
                    required
                  ></textarea>
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Card Entry</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateCardEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      description: null,
      rate: null,
      range: null,
      tac: null,
    });
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        const response = await apiPost(
          `/admin/cards/${route.params.giftCardId}`,
          formData.value
        );
        ShowSuccess("Entry Created Successfully", () => {
          formData.value = {
            description: null,
            rate: null,
            range: null,
            tac: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
